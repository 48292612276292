import React,{useState} from 'react';
import {TbEyeClosed} from 'react-icons/tb';
import { IoEyeOutline } from 'react-icons/io5';
import { IoStar, IoStarOutline } from 'react-icons/io5';
import '../../css/PopUpCardsCSS/CustomNuggetModel.css';
import StarRatings from 'react-star-ratings';
import { MdAdd } from 'react-icons/md'
import axios from 'axios';
import constants from '../../config/constants';
import { useNavigate } from 'react-router-dom';

export default function CustomNuggetModel({iterationId, onClose, showModal, setIsPopupClosed}) {
 

  const [isStared, setIsStared] = useState(false);
  const [isHideFlag, setIsHideFlag] = useState(true);
  const [starRating, setStarRating] = useState(0);
  const [feedbackText, setFeedbackText] = useState('');
  const [customNuggetText, setCustomNuggetText] = useState('');
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const navigate = useNavigate()

  const handleStarClick = () => {
    setIsStared(!isStared);
  };

  const handleEyeClick = () => {
    setIsHideFlag(!isHideFlag);
  }; 
  const handleCustomNuggetChange = (event) => {
    setCustomNuggetText(event.target.value);
  };
  const handleRatingChange = (newRating) => {
    setStarRating(newRating);
  };

  const handleTextareaChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const onSubmitCustomNugget  = async () => {
    setIsButtonClicked(true)
    const starredValue = isStared ? 1 : 0;
    const hideFlagValue = isHideFlag ? 0 : 1;
    const dataToSend = {
      is_stared:starredValue,
      is_hide_flag:hideFlagValue,
      extract:customNuggetText,
      iteration_id: iterationId,
    };
    const apiUrl =`${constants.BACKEND_SERVER_BASEURL}/nuggets/addNewNugget`;

    try {
      const response = await axios.post(apiUrl, dataToSend);
      setIsPopupClosed(true)
      onClose();
    } catch (error) {
      // console.error('Error sending data to the backend:', error);
    }
  };

  if (!showModal) return null;
  return (
    <div>
        <div className='overlay-styles'>
            <div className='custom-modals'>
              <p className="close d-flex justify-content-end" onClick={()=>onClose()}>&times;</p>
              <div className='container-fluid'>
                <div className='row d-flex flex-row'>
                      <div className='col-4'>
                        <p className='nugget-popup-head d-flex align-items-center'>Enter Custom Nugget 
                        {isStared ? (
                          <IoStar className={`star_icon  star_color`} onClick={handleStarClick} />
                        ) : (
                          <IoStarOutline className={`star_icon`} onClick={handleStarClick} />
                        )}
                        {isHideFlag ? (
                          <IoEyeOutline className={`eye_icon visible`} onClick={handleEyeClick} />
                        ) : (
                          <TbEyeClosed className={`eye_icon`} onClick={handleEyeClick} />
                        )}
                        </p>
                          <textarea
                            className='nugget-text-field'
                            placeholder='Input your text here for custom Nugget'
                            value={customNuggetText}
                            onChange={handleCustomNuggetChange}
                          ></textarea>
                      </div>
                      <div className='col-5 container_visibility'>
                        <div className='middle_container p-1 '>
                          <h3 className="reference_head_popup">Key Info</h3>
                          <div className='star_rating d-flex flex-column justify-content-between'>
                            <div className="description-container">
                              <p className="description_popup">-</p>
                            </div>
                            <div className='star_rating_container align-self-end mb-3'>
                            <StarRatings
                              rating={starRating}
                              starRatedColor="orange"
                              className="star_rating_popup"
                              changeRating={handleRatingChange}
                              numberOfStars={5}
                              starDimension="12px"
                            />
                          </div>
                        </div>
                        <div className='feedback_container d-flex justify-content-between'>
                          <div><p className="feedback_heading_popup">Feedback</p></div>
                          <div className=''><p className='submit_btn_popup' >Submit</p></div>
                        </div>
                        <div className='textarea_container'>
                          <textarea
                            rows="2"
                            className="extract_textarea"
                            value={feedbackText}
                            onChange={handleTextareaChange}
                          />
                          </div>
                        </div>
                        <div className='middle_container p-1'>
                                <h3 className="reference_head_popup">Timelines</h3>
                                  <div>
                                <div className='star_rating d-flex flex-column justify-content-between'>
                                  <div className="description-container">
                                    <p className="description_popup">-</p>
                                  </div>
                                  <div className='star_rating_container align-self-end mb-1'>
                                  <StarRatings
                                      rating={starRating}
                                      starRatedColor="orange"
                                      changeRating={handleRatingChange}
                                      numberOfStars={5}
                                      starDimension="12px"
                                    />
                                  </div>
                                </div>
                                <div className='feedback_container d-flex justify-content-between'>
                                  <div><p className="feedback_heading_popup">Feedback</p></div>
                                  <div className=''><p className='submit_btn_popup' >Submit</p></div>
                                </div>
                                <div className='textarea_container_popup'>
                                  <textarea rows="2" id={0}
                                    className="extract_textarea_popup"
                                    value={feedbackText}
                                    onChange={handleTextareaChange}
                                  >
                                  </textarea>
                                </div>
                                </div>
                        </div>
                        <div className='middle_container p-1'>
                                <h3 className="reference_head_popup">References</h3>
                                  <div>
                                <div className='star_rating d-flex flex-column justify-content-between'>
                                  <div className="description-container">
                                    <p className="description_popup">-</p>
                                  </div>
                                  <div className='star_rating_container align-self-end mb-3'>
                                    <StarRatings
                                      rating={starRating}
                                      starRatedColor="orange"
                                      changeRating={handleRatingChange}
                                      numberOfStars={5}
                                      starDimension="12px"
                                    />
                                  </div>
                                </div>
                                <div className='feedback_container d-flex justify-content-between'>
                                  <div><p className="feedback_heading_popup">Feedback</p></div>
                                  <div className=''><p className='submit_btn_popup' >Submit</p></div>
                                </div>
                                <div className='textarea_container_popup'>
                                  <textarea rows="2"
                                    className="extract_textarea_popup"
                                    value={feedbackText}
                                    onChange={handleTextareaChange}
                                  >
                                  </textarea>
                                </div>
                                </div>
                        </div>
                      </div>
                      <div className='col-3 listed_actions_container_popup d-flex flex-column justify-content-between container_visibility'>
                        <div className='d-flex flex-column '>
                          <h4 className='listed_actions_heading_popup'>Listed Actions</h4>
                          <div>
                              <p className='listed_actions_paragraph'></p>
                              <div className='d-flex justify-content-between '>
                              </div>
                          </div>
                        </div>
                        <div className='d-flex flex-column align-self-center hr_line'>
                          <button className="button1  d-flex align-items-center justify-content-center add_action_btn">
                            <MdAdd /> Add Action
                          </button>
                        </div>
                      </div>
                </div>
              </div>
                  <div className=' d-flex justify-content-between mt-2'>
                      <button className='close_button' onClick={()=>onClose()} >Close</button>
                      <button className='close_button submit-popup_button' onClick={onSubmitCustomNugget} disabled={isButtonClicked}>Submit Custom Nugget</button>
                      {/* <button className='close_button submit-popup_button' onClick={()=>onClose()} >Submit Custom Nugget</button> */}
                  </div>
            </div>
        </div>
    </div>
  )
}
