import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from '../config/Loader';
import '../css/DataCollection.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-accessible-accordion/dist/fancy-example.css';
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import RadialSeparators from './RadialSeparators.js';
import 'react-circular-progressbar/dist/styles.css';
const constants = require('../config/constants.js');


const DataCollection = ({ groupedCollectionData, selectedJuri }) => {
  const [selectedCategory, setSelectedCategory] = useState('Environment');
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const textareaRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const { iterationId, companyId }                      = useParams();

  const [eDataPointsSummary, setEDataPointsSummary]     = useState(0);
  const [sDataPointsSummary, setSDataPointsSummary]     = useState(0);
  const [gDataPointsSummary, setGDataPointsSummary]     = useState(0);
  
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    // setSelectedSubcategory([]);
  };

  const handleSubcategorySelect = (fields) => {
    setSelectedSubcategory(fields);
    // Auto-populate data_point_value
    const initialValues = {};
    fields.forEach((item) => {
      initialValues[item.data_point_id] = item.data_point_value || '';
    });
    setInputValues(initialValues);
  };
  
  const getDataCollection = async () => {
    axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getSummaryReport/${iterationId}/${companyId}/${selectedJuri}`)
      .then((response) => {
        // console.log(response)
          setEDataPointsSummary(findPercentage(response.data.eDataPointsSummary[0].non_empty_count, response.data.eDataPointsSummary[0].empty_count));
          setSDataPointsSummary(findPercentage(response.data.sDataPointsSummary[0].non_empty_count, response.data.sDataPointsSummary[0].empty_count));
          setGDataPointsSummary(findPercentage(response.data.gDataPointsSummary[0].non_empty_count, response.data.gDataPointsSummary[0].empty_count));
      })
      .catch((error) => {
          console.error('error:', error);
        });
  }

  const handleInputChange = (e, dataPointId) => {
    const { value } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [dataPointId]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const updatedData = selectedSubcategory.map((item) => ({
      ...item,
      value: inputValues[item.data_point_id] || '',
    }));

    try {
      await axios.post(
        `${constants.BACKEND_SERVER_BASEURL}/dataPoints/updateDataPointsById`,
        {
          updatedDataPointsData: updatedData,
        }
      );

      // Handle the response
      // console.log(response.data);
    } catch (error) {
      // Handle error
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDataCollection()
  },[selectedJuri])

  useEffect(() => {
    // Adjust textarea heights on initial render and whenever inputValues change
    textareaRefs.current.forEach((textarea) => {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    });
  }, [inputValues]);

  useEffect(() => {
    // Update state values when groupedCollectionData is refreshed
    if (groupedCollectionData && groupedCollectionData.Environment) {
      setSelectedCategory('Environment');
      const climateChangeSubcategory = groupedCollectionData.Environment;
  
      if (climateChangeSubcategory && climateChangeSubcategory["Climate Change"]) {
        let climateData = climateChangeSubcategory["Climate Change"];
        setSelectedSubcategory(climateData);
        handleSubcategorySelect(climateData)
      }
    }
  }, [groupedCollectionData]);


  const handleTextareaRef = (textarea, index) => {
    if (textarea && !textareaRefs.current.includes(textarea)) {
      textareaRefs.current[index] = textarea;
    }
  };

  function findPercentage(completedDataPoints, incompleteDataPoints)
  {
      var percent = (completedDataPoints/(completedDataPoints+incompleteDataPoints))*100;
      return Math.round(percent);
  }



  return (
    <div>
      {loading && <Loader />}
      <div className="container-fluid main-container">
        <div className="row px-2">
          <div className="col-3">
              <Accordion className='custom-accordion' preExpanded={['Environment']} >
                {Object.entries(groupedCollectionData).map(([category, subcategories]) => (
                  <AccordionItem className='accordion-item' key={category} uuid={category}>
                    <AccordionItemHeading   onClick={() => handleCategorySelect(category)}>
                      <AccordionItemButton className={`accordion__button ${selectedCategory === category ? 'active' : ''}`}>
                        <div className='d-flex justify-content-between align-items-center'>
                        {category} 
                        <div style={{ width: 30, height: 30 }} className='progress-bar'>
                          <CircularProgressbarWithChildren className='custom-circular-bar'
                            value={category === 'Environment' ? eDataPointsSummary : category === 'Social' ? sDataPointsSummary : gDataPointsSummary}
                            text={`${category === 'Environment' ? eDataPointsSummary : category === 'Social' ? sDataPointsSummary : gDataPointsSummary}%`}
                            strokeWidth={10}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              pathColor: '#00A656',
                              trailColor: '#E5F6EE',
                              textColor: '#000'
                            })}
                          >
                            <RadialSeparators
                              count={12}
                              style={{
                                background: "#fff",
                                width: "1px",
                                // This needs to be equal to props.strokeWidth
                                height: `${20}%`
                              }}
                            />
                          </CircularProgressbarWithChildren>
                        </div>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      {                        
                          <ul className="list-group">
                            {Object.entries(subcategories).map(([subcategory, fields]) => (
                              <li key={`${subcategory}-${fields.id}`} className="sub-category-items">
                                <p
                                  className="sub-categories"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleSubcategorySelect(fields)}
                                >
                                  {subcategory}
                                </p>
                              </li>
                            ))}
                          </ul>
                      }
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
          </div>

          <div className="col-9">
            <div className="inputs">
              {/* <h3>Input Fields:</h3> */}

              {selectedSubcategory.length > 0 && (
                <form className="form-section" onSubmit={handleSubmit}>
                  {selectedSubcategory.map((item, index) => (
                    <div key={item.data_point_id} className="form-group input-form-group">
                      <label htmlFor={item.field} className='data-point'>{item.field}</label>
                      <textarea
                        ref={(textarea) => handleTextareaRef(textarea, index)}
                        type="text"
                        id={item.field}
                        name={item.field}
                        value={inputValues[item.data_point_id] || ''}
                        onChange={(e) => handleInputChange(e, item.data_point_id)}
                        className="form-control answer-text"
                      />
                      {/* {console.log(item)} */}
                    </div>
                  ))}
                  <button type="submit" className="btn btn-success success-btn save-button">
                    Save
                  </button>
                  {/* <button type="button" className="btn btn-default default-btn">
                  Cancel
                </button> */}
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataCollection;
