import React, { useState } from 'react';
import '../css/Login.css';
import Vector from '../assets/Vector.png';
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import SignupNavbar from './SignupNavbar';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import NavbarContext from './NavbarContext';
import constants from '../config/constants';
import axios from 'axios';

export default function Login() {
  const { setIsLoginPage } = useContext(NavbarContext);
  let navigate = useNavigate();

  const signupClick = () => {
    navigate('/Signup');
    setIsLoginPage(false);
  };

  // email validation
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [email_error, setEmailError] = useState("");
  const [password_error, setPasswordError] = useState("");

  const emailPattern = /^(([^<>()[\]\\.,;:@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\s]{2,}))$/;

  const validateEmail = () => {
    if (email.trim() === "") {
      setEmailError("Enter Email Address");
      return false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Enter Valid Email Address");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const validatePassword = () => {
    if (password.trim() === "") {
      setPasswordError("Enter Password");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  // password eye image functionality
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
    setPasswordType(passwordVisible ? "password" : "text");
  };

  // login API integration
  const url = `${constants.BACKEND_SERVER_BASEURL}/users/loginUser`
  const [loginError, setLoginError] = useState("");
  const credentials = {
    email: email,
    password: password
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    var isValidEmail = validateEmail();
    var isValidPassword = validatePassword();

    if (isValidEmail && isValidPassword) {
      try {
        const response = await axios.post(url, credentials);
        const data = response.data;
        if (data.error_code === 0) {
          localStorage.setItem('userId',data.userId)
          localStorage.setItem("accessToken",data.accessToken)
          navigate('/');
        } else if (data.error_code === 401) {
          setLoginError("Invalid Credentials");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className='bg'>
      <div className='bg_container row'>
        <SignupNavbar />
        <div className='left_container col-12 col-lg-6'>
          <div>
            <p className='login_heading'>Reduce  <span className='login_heading_highlight'>90% </span> of your time and effort</p>
            <p className='login_paragraph'>By using ESGPilot, businesses and organizations can reduce the effort and resources required for ESG reporting, enabling them to focus more on their sustainability initiatives and less on the intricacies of report generation.
              <div
                style={{ marginBottom: '0.1em' }}><br />
              </div>
              With Smart ESG AI, you can rapidly extract the relevant ESG mandatory fields, identify gaps in your ESG reporting, and generate stunning reports.
            </p>
          </div>
        </div>
        <div className='right_container col-12 col-lg-6'>
          <div className='form_container'>
            <form onSubmit={handleLogin}>
              <div className='input_box_logo'>
                <img src={Vector} alt='vector' className='vector_image' />
                <p className='esgpilot_heading'>ESG<span className='pilot'>PILOT</span></p>
              </div>
              {loginError && (<p style={{ color: 'red', textAlign: "center" }}>{loginError}</p>)}
              <div>
                <input
                  type='email'
                  placeholder='Email Address'
                  id='email'
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                  className='input_box'
                />
              </div>
              {email_error && (<p style={{ color: 'red' }}>{email_error}</p>)}

              <div className='password_img_container'>
                <input
                  type={passwordType}
                  placeholder='Password'
                  id='password'
                  value={password}
                  onChange={(e) => { setPassword(e.target.value) }}
                  className='input_box'
                />
                {passwordVisible ? <PiEyeLight className='eye_image' onClick={togglePasswordVisibility} /> : <PiEyeSlash className='eye_image' onClick={togglePasswordVisibility} />}
              </div>
              {password_error && (<p style={{ color: 'red' }}>{password_error}</p>)}

              <div className='checkbox_container'>
                <label className='remember_me_text'>
                  <input type="checkbox"
                    //  checked={isChecked} 
                    //  onChange={handleCheckboxChange}
                    className='checkbox_style'
                    id='remember_checkbox'
                  />
                  Remember me
                </label>
                <span className='forget_password_text'>Forget your password?</span>
              </div>
              <button type='submit' className='signup_button' >login</button>
              <p className='signup_text'>Don't Have An Account? <span onClick={signupClick} className='login_link_sgu'>Sign up</span></p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
