/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

let constants = {
    BACKEND_SERVER_BASEURL: window.location.hostname === "localhost" ? 'http://localhost:3000' : window.location.protocol + "//" + window.location.hostname + "/app",
    // BACKEND_SERVER_BASEURL: 'https://peak.px4app.com/app',
    PEAK_AI_BASE_URL: 'https://peak.px4app.com/ai',
    ITERATION_STATUS: {INSERTING_DATA: 0, DATA_INSERTED_SUCCESSFULLY: 1, PROCESSING_ITERATION: 2, DONE: 3},
    ITERATION_STATUS_IDS: ['NO DATA', 'PENDING', 'PROCESS STARTED', 'DONE'],
    FLAG: {ENABLE: 1, DISABLE: 0}
}
module.exports = Object.freeze(constants);