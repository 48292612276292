import React, { useState, useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import "../css/fileUpload.css";
import axios from "../config/axiosConfig";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../config/Loader";
import CustomNuggetModel from "./PopUpCards/CustomNuggetModel.js";
import { FaArrowRight } from "react-icons/fa6";

const constants = require("../config/constants.js");
const fetchJurisdictions = async () => {
  try {
    const response = await axios.get(
      `${constants.BACKEND_SERVER_BASEURL}/fileUpload/getJurisdictions`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching jurisdictions:", error);
    return [];
  }
};
function FileUploadComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [iterationId, setIterationId] = useState(null);
  const [formData, setFormData] = useState({
    file: null,
    juri_id: "",
    tenement_type: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [jurisdictions, setJurisdictions] = useState([]);
  const [aiAssetDetails, setAiAssetDetails] = useState('')

  useEffect(() => {
    // Fetch jurisdictions and update state
    fetchJurisdictions()
      .then((data) => setJurisdictions(data))
      .catch((error) => console.error("Error setting jurisdictions:", error));
  }, []);

  const handleChange = (e) => {
    setErrorMessage("");
    const { name, value, type } = e.target;
    const newValue = type === "file" ? e.target.files[0] : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files[0].type === 'application/pdf'){
    const fileName = e.target.value.split('\\').pop();
    const selectedFileNameParagraph = document.getElementById('selectedFileName');
    selectedFileNameParagraph.innerText = fileName || "No file selected";
    setErrorMessage("");
    const { name, value, type } = e.target;
    const newValue = type === "file" ? e.target.files[0] : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  } else{
    setErrorMessage("Please select a PDF file");
  }
  };

  useEffect(() => {
    if (parseInt(formData.start_page) >= parseInt(formData.end_page)) {
      const newErrorMessage = "Start page must be less than end page";
      setErrorMessage(newErrorMessage);
    } 
  }, [formData]);

  const handleRange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = (e) => {
    
    e.preventDefault();
    // setFormData({
    //   file: null,
    //   juri_id: "",
    //   tenement_type: "",
    //   page_range: null,
    //   start_page: "",
    //   end_page: "",
    // });
  };

  const handleUpload = async () => {
    if (!formData.file) {
      setErrorMessage("Please select a file");
      return;
    }
  
    if (!formData.juri_id) {
      setErrorMessage("Please choose a jurisdiction");
      return;
    }
  
    if (!formData.tenement_type) {
      setErrorMessage("Please enter a tenement type");
      return;
    }

    setLoading(true);

    // Insert new iteration (report) with status with the status id 0 (0-Inserting Data related to iteration) in database
    // const iteration_id = await axios.post(
    //   `${constants.BACKEND_SERVER_BASEURL}/iterations/insertNewIteration`,formData
    // );

    const formDataObject = new FormData();
    formDataObject.append('file', formData.file);
    formDataObject.append('juri_id', formData.juri_id);
    formDataObject.append('tenement_type',formData.tenement_type );

    try {
      const response = await axios.post(
        `${constants.BACKEND_SERVER_BASEURL}/fileUpload/uploadFileToStorage`,
        formDataObject
      );
      setIterationId(response.data.iteration_id)
    } catch (error) {
      console.error("Upload error:", error);
    } finally {
      setLoading(false);
    }

    // Update status of iteration (report) with the status id 1 (1-Data Inserted successfully) in database
    // await axios.post(`${constants.BACKEND_SERVER_BASEURL}/iterations/updateIterationStatus`, { iteration_id: iteration_id.data });
    // setModalOpen(true);
     
  };

  useEffect(() => {
    if (iterationId !== null) {
      navigate(`/DocPreview/${iterationId}`);
    }
  }, [iterationId]);

  //submission completed popup component functionality
  const handlePopupModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const getUrl = async () => {
      try {
        const aiAssetUrl = `${constants.BACKEND_SERVER_BASEURL}/nuggets/getTrainedModelData`
        const response = await axios.get(aiAssetUrl);
        setAiAssetDetails(response.data.results);
      }catch (error){
        console.error(error);
      }
    };
      getUrl();
    }, []);


  return (
    <div className="center-container">
      <div className="fieupload-card">  
        <Form onSubmit={handleSubmit}>
          <label className="fileupload-label">Select File</label>
          <div className="custom-upload mb-4">
            <p id="selectedFileName" className="selected-file-text"></p>

            <label htmlFor="fileInput" className="custom-file-upload">
              <span className="upload-select-text">Select</span>
              <input type="file" id="fileInput" name="file" onChange={handleFileChange} />
            </label>
          </div>
          <Row className="mb-4">
            <Form.Group as={Col} controlId="formGridState">
              <label className="fileupload-label">Jurisdiction</label>
              <Form.Select
                defaultValue="Choose Jurisdiction"
                name="juri_id"
                className="juridictions-select"
                value={formData.juri_id}
                onChange={handleChange}>
                <option value="">Choose Jurisdiction</option>
                {jurisdictions && jurisdictions.length > 0 ? (
                  jurisdictions.map((jurisdiction) => (
                    <option
                      key={jurisdiction.juri_id}
                      value={jurisdiction.juri_id}>
                      {jurisdiction.juri_name_short}
                    </option>
                  ))
                ) : (
                  <option value="">No jurisdictions available</option>
                )}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridZip">
              <label className="fileupload-label">Tenement</label>
              <Form.Control
                name="tenement_type"
                className="tenement-input"
                value={formData.tenement_type}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          {/* <Row className="mb-4">
            <label className="fileupload-label">Scan Range</label>
            <Form.Group controlId="formRadio">
              <Form.Check
                inline
                type='radio'
                name="page_range"
                label="Page Range"
                value="range"
                checked={selectedOption === "range"}
                onChange={(e) => {
                  handleRange(e);
                  handleChange(e);
                }}
                className={formData.page_range === 'range' ? 'selected-radio' : 'not-selected'}

              />
              <Form.Check
                inline
                name="page_range"
                type='radio'
                label="All Pages"
                value="all"
                checked={selectedOption === "all"}
                onChange={(e) => {
                  handleRange(e);
                  handleChange(e);
                }}
                className={formData.page_range === 'all' ? 'selected-radio' : 'not-selected'}
              />
            </Form.Group>
          </Row>
          {selectedOption === "range" && (
            <Row>
              <Form.Group as={Col} controlId="formInput1">
                <label className="fileupload-label">Start Page</label>
                <Form.Control
                  name="start_page"
                  className="tenement-input"
                  value={formData.start_page}
                  placeholder="Enter start Page"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formInput1">
                <label className="fileupload-label">End Page:</label>
                <Form.Control
                  name="end_page"
                  className="tenement-input"
                  value={formData.end_page}
                  placeholder="Enter end Page"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
          )} */}
          <div>
            <p className="ai_model_details">AI Model trained with <span className="ai_model_detail_points">({aiAssetDetails.keyinfo_feedback_points},{aiAssetDetails.reference_feedback_points},{aiAssetDetails.timeline_feedback_points})</span> data points.</p>
            <p className="ai_model_details">Pending <span className="ai_model_detail_points">({aiAssetDetails.keyinfo_feedback_pending_points},{aiAssetDetails.reference_feedback_pending_points},{aiAssetDetails.timeline_feedback_pending_points})</span>  points yet untrained.</p>
            <Link className='' to={"/AiModelTraining"}><p className="retrain_link">Click here to train the model before proceeding <FaArrowRight /></p></Link>
          </div>
          <div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
          <div className="d-grid gap-2 ">
            <Button className="doc-upload-button" type="submit" style={{background: "#F77D24",border:"none"}}
                onClick={handleUpload}>
              UPLOAD
            </Button>
            
          </div>
        </Form>
      </div>
      {loading && <Loader />}

      {modalOpen && (
        <CustomNuggetModel
          onCancel={handlePopupModal}
          onClose={handlePopupModal}
          setLoading={setLoading}
        />
      )}
    </div>
  );
}

export default FileUploadComponent;
