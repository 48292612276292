import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import '../../css/PopUpCardsCSS/DeleteConfirmationModal.css';
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';
import constants from '../../config/constants';

export default function DeleteConfirmationModal({showModal,onExit, iterationId}) {
    const navigate = useNavigate()
  if (!showModal) return null;

  const url = `${constants.BACKEND_SERVER_BASEURL}/fileUpload/deleteFileFromStorage/${iterationId}`

  const onDeleteDocument = async () => {
    try{
    await axios.post(url)
    navigate(0)
    onExit()
    } catch(error){
        console.log(error)
    }
    
  }

  return (
    <div>
       <div className='delete-overlay-styles'>
        <div className='delete-modal'>
            <div onClick={onExit}>
                <RxCross2 className='modal-cross-icon' />
            </div>
          <div className='col-12 preview_container'>
              <p className='confirmation-heading'>Confirmation</p>
            <div className='d-flex mt-4'>
                  <p className='confirmation-message'>Are you sure to delete this document?</p>
            </div>
            <div className='frameworks_button d-flex justify-content-end mt-4'>
                <button type='submit' className='back-button' onClick={onExit}>Back</button>
                <button type='submit' className='back-button delete-button' onClick={onDeleteDocument}>Yes, Delete</button>
            </div>
          </div>
        </div>
       </div>
    </div>
  )
}
