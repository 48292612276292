import '../css/Navbar.css';
import { useState, useContext } from 'react';
import { BiHomeAlt } from "react-icons/bi";
import {TbLogout} from 'react-icons/tb';
import { PiPolygon } from "react-icons/pi";
import { BsRecycle } from "react-icons/bs";
import { HiMiniBars3 } from "react-icons/hi2";
import NavbarContext from './NavbarContext';
import { useNavigate } from 'react-router-dom';
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css';

const Navbar = () => {
    const {isExpanded, setIsExpanded} = useContext(NavbarContext)
    const [activeTab, setActiveTab] = useState('home')
    const navigate = useNavigate()

    const onExpand = () => {
        setIsExpanded(!isExpanded)
    }

    const onHome = () => {
        setActiveTab('home')
        navigate('/')
    }

    const customStyles = {
        popperOptions: {
          modifiers: [
            {
              name: 'arrow',
              options: {
                element: '.arrow-custom',
              },
            },
            {
              name: 'applyStyles',
              enabled: false,
            },
          ],
        },
      };

    const extendedNavbar = isExpanded ? 'extended' : ''
    const extendedItem = isExpanded ? '' : 'extended-item'

    return(
        <div className={`navbar-container ${extendedNavbar}`}>
            <div>
                <div onClick={onExpand} className={`bars-container`}>
                    {isExpanded ? <div className='px4-text-container'> <HiMiniBars3 className='cross-icon' /></div> : <HiMiniBars3 className='bars-icon'/>}
                </div>

                <nav>
                    <ul className='navg-list'>
                        <Tippy content="Home" className='custom-tip'  popperOptions={customStyles} arrow={true} placement='right' animation="fade" duration={200} position="right" theme="custom-theme" disabled={isExpanded}>
                            <li className={`navg-item ${extendedItem} ${activeTab === 'home' ? 'active' : 'inactive'}`} onClick={onHome} ><BiHomeAlt className='nav-icon' />{isExpanded && <span className='expanded-text'>Home</span>}</li>
                        </Tippy>
                        <Tippy content="Pathway Outline" className='custom-tip'  popperOptions={customStyles} arrow={true} placement='right' animation="fade" duration={200} position="right" disabled={isExpanded}>
                        <li disabled className={`navg-item disable ${extendedItem} ${activeTab === 'outline' ? 'active' : 'inactive'}`}  ><PiPolygon className='nav-icon' />{isExpanded && <span className='expanded-text'>Pathway Outline</span>}</li>
                        </Tippy>
                        <Tippy content="Pathway Editor" className='custom-tip'  popperOptions={customStyles} arrow={true} placement='right' animation="fade" duration={200} position="right" disabled={isExpanded}>
                        <li disabled className={`navg-item disable ${extendedItem} ${activeTab === 'editor' ? 'active' : 'inactive'}`}  ><BsRecycle className='nav-icon' />{isExpanded && <span className='expanded-text'>Pathway Editor</span>}</li>
                        </Tippy>
                    </ul>
                </nav>
            </div>
            <div disabled className={`navg-item disable logout-container ${extendedItem}`}>
              <Tippy content="Logout" className='custom-tip'  popperOptions={customStyles} arrow={true} placement='right' animation="fade" duration={200} position="right" disabled={true}>
                <div className=''>
                  <TbLogout className='logout-icon' /> {isExpanded && <span className='expanded-text'>Logout</span>}
                </div>
              </Tippy>
            </div>
        </div>
    )
}

export default Navbar