import React from 'react'
import '../../css/PopUpCardsCSS/PreviewOfTheProcess.css';
import upload from '../../assets/Upload.png';
import reviewExtracts from '../../assets/Frame 1000003102.png';
import reports from '../../assets/Reports (1).png'


export default function PreviewOfTheProcess({showModal,onExit}) {
  if (!showModal) return null;
  return (
    <div>
       <div className='overlay-styles'>
        <div className='preview-modal'>
          <div className='col-12 preview_container'>
            <div className='container_gap'>
              <p className='preview_heading'>Preview of the process</p>
              <p className='preview_paragraph'>Upload all the documents you have we will help you create your report</p>
            </div>
            <div className='d-flex '>
              <div className='preview_image d-flex flex-column align-items-center'>
                  <img src={upload} className='upload_image' alt='uploadImage'/>
                  <p className='upload_documents mt-3'>Upload Documents</p>
              </div>
              <div className='framework_image  d-flex flex-column align-items-center'>
                  <img src={reviewExtracts} className='review_image' alt='reviewImage'/>
                  <p className='upload_documents mt-3 mb-0'>Review Extracts</p>
              </div>
            </div>
            <div className='framework_image d-flex align-items-end '>
                <img src={reports} className='reports_image' alt='reportsImage'/>
                <p className='upload_documents'>Download Report</p>
            </div>
            <div className='frameworks_button d-flex justify-content-end mt-5'>
                <button type='submit' className='preview_next_button preview_back_button' onClick={onExit}>Next</button>
            </div>
          </div>
        </div>
       </div>
    </div>
  )
}
