import React from "react";
import { Style } from "victory";

const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct }) => {
    const r = 70;
    const circ = 1.35 * Math.PI * r;
    const strokePct = ((100 - pct) * circ) / 100;
    const backgroundColor = pct === 0 ? colour : "transparent";
    const strokeColor = strokePct !== circ ? colour : "";
  
    return (
      <g>
        <circle
          r={r}
          cx={100}
          cy={100}
          fill={backgroundColor}
          stroke={strokeColor}
          strokeWidth={"1rem"}
          strokeDasharray={circ}
          strokeDashoffset={pct ? strokePct : 0}
          strokeLinecap="round"
        />
        {pct === 0 && (
          <circle
            r={r}
            cx={100}
            cy={100}
            fill="transparent"
            stroke={colour}
            strokeWidth={"1rem"}
            strokeLinecap="round"
          />
        )}
      </g>
    );
  };

const Text = ({ percentage }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      className=""
      fontSize={"1.5em"}
    >
      {percentage.toFixed(0)}%
    </text>
  );
};

const Pie = ({ percentage, colour, backgroundColor }) => {
  const pct = cleanPercentage(percentage);
  return (
    <svg width={200} height={200}>
      <g transform={`rotate(147 ${"100 100"})`}>
        <Circle colour={backgroundColor} />
        <Circle colour={colour} pct={pct} />
      </g>
      <Text percentage={pct} />
    </svg>
  );
};

export default Pie;
