import '../../css/PopUpCardsCSS/DocumentPageLimitModal.css';
import { RxCross2 } from "react-icons/rx";
import { IoWarningOutline } from "react-icons/io5";

export default function DocumentPageLimitModal({showModal,onExit}) {
  if (!showModal) return null;

  return (
    <div>
       <div className='limit-overlay-styles'>
        <div className='limit-modal'>
            <div onClick={onExit}>
                <RxCross2 className='modal-cross-icon' />
            </div>
          <div className='col-12'>
              <p className='limit-heading'><IoWarningOutline className='warning-icon' />Page Limit Exceeded</p>
            <div className='d-flex mt-4'>
                  <p className='limit-error-message'>Document cannot be processed due to the page limit.</p>
            </div>
            <div className='d-flex justify-content-end mt-2'>
                <button type='submit' className='limit-close-button' onClick={onExit}>Close</button>
            </div>
          </div>
        </div>
       </div>
    </div>
  )
}
