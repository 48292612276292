import React,{ useState} from 'react';
import '../../css/PopUpCardsCSS/LetsGetStarted.css';
import countriesData from '../../countries.json';
import axios from 'axios';
import constants from '../../config/constants';




export default function LetsGetStarted({showModal, onAction,setCompanyId}) {

  //form data
  const [formData,setFormData]=useState({
    first_name:"",
    last_name:"",
    role:"",
    company_name:"",
    hq_country:"",
  })

  //required input fields
  const validateInputFields = () => {
    if ((formData.first_name.trim() === "") || (formData.last_name.trim() === "") || (formData.role.trim() === "") || (formData.company_name.trim() === "") || (formData.hq_country.trim() === "")) {
      return false;
    } else {
      return true;
    }
  };
    
function handleChange(e) {
  const { name, value } = e.target;
  setFormData((prev) => {
    return { ...prev, [name]: value };
  });
  };
  
    const userId=localStorage.getItem('userId')
    const url=`${constants.BACKEND_SERVER_BASEURL}/users/addUserDetails/${userId}`;
    const handleSubmit = async (e) => {
      e.preventDefault();
      var validateInput = validateInputFields();
      if(validateInput){
      try {
        const response = await axios.post(url, formData);
        //console.log(response.data);
        const company_id=response.data.company_id
        setCompanyId(company_id)
        onAction();
      } catch (error) {
        console.error('Error making API request:', error);
      }
    }
    };

  if (!showModal) return null;
  return (
    <div>
        <div className='overlay-styles'>
          <div className='lgs-modal'>
            <div className='lets_get_started_container'>
              <div className='container_gap col-12'>
                <p className='form_heading'>Lets get you started</p>
                <p className='form_paragraph'>This helps us determine the frameworks you have to fill in</p>
              </div>
              
              <form onSubmit={handleSubmit}>
                <div className=' input_field_container d-flex flex-wrap justify-content-between align-items-center'>
                  <div className='input_fields'>
                    <label htmlFor='firstName' className='form_label'>First Name</label>
                    <input type='text' 
                    className='form_input_box' 
                    id="firstName" required
                    name='first_name'
                    onChange={handleChange}
                    
                    />
                  </div>
               <div className='input_fields'>
                  <label htmlFor='lastName' className='form_label'>Last Name</label>
                  <input type='text'
                    className='form_input_box' 
                    id="lastName" required
                    name='last_name'
                    onChange={handleChange}
                   
                  />
               </div>
               <div className='input_fields'>
                  <label htmlFor='companyName' className='form_label'>Company Name</label>
                  <input type='text'
                  className='form_input_box' 
                  id="companyName" required
                  name='company_name'
                  onChange={handleChange}
                  />
               </div>
               <div className='input_fields'>
                  <label htmlFor='country' className='form_label'>HQ Country <span className='HQ_country'>(Company Head Quarter's)</span></label>
                  <select className='form_input_box' id="country" required name='hq_country' onChange={handleChange}>
                    {countriesData.map((eachCountry)=> <option key={eachCountry.id} value={eachCountry.name}>{eachCountry.name}</option>
                    )}
                  </select>
               </div>
               <div className='input_fields'>
                  <label htmlFor='role' className='form_label'>Role In Company</label>
                  <input type='text' 
                  className='form_input_box' 
                  id="role"
                  name='role'
                  required
                  onChange={handleChange}
                  />
               </div>
                </div>
                <div className='d-flex justify-content-end '>
                  <button type='submit' className='lets_get_you_started_button'>Next</button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
  ) 
}

