import '../css/home.css';
import HeaderComponent from '../components/HeaderComponent';
import FileUpload from '../components/FileUpload';
import { useContext } from 'react';
import NavbarContext from '../components/NavbarContext';
import MyDocumentsBar from './MyDocumentsBar';


export default function Home() {
  const {isExpanded} = useContext(NavbarContext)

  // Component code here
  return (
        <div className={`home-container ${isExpanded && 'expanded'}`}>
          <HeaderComponent/>
          <div className='home-bg'>
            {/* <FileUpload/> */}
            <MyDocumentsBar/>
          </div>
          <div></div>
        </div>
  );
}